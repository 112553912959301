import { getUserLocale } from './i18n'

export type DateLike = string | number | Date

export type DateRange = { startDate: DateLike; endDate: DateLike }

/**
 * Throughout the app we use the YYYY-MM-DD date string format which simplifies
 * using dates. We then we convert these to date objects within the functions
 * in this file using normalizeToDateObject(). When converting YYYY-MM-DD to a date,
 * ie new Date('2022-08-01'), this actually converts it to UTC which can end up
 * being a different day. In Denver that ends up being July 31st since it's 6hrs
 * ahead. So we want to always convert the dates to local via the format
 * YYYY/MM/DD before we do operations. You might think "why not use that format
 * throughout the app?" but we can't use YYYY/MM/DD as a URL param so we decided
 * to use the YYYY-MM-DD format instead which looks better in the urls.
 *
 * Given a date as a sting, number, Date or undefined, make sure we have a Date
 * to work with. If date is a dashed date string without a time stamp (ie
 * 2022-01-01), it is treated as a local date to ensure there is no conversion
 * between UTC. If date is undefined, then the current local date is used.
 */
export const normalizeToDateObject = (date: DateLike = new Date()) => {
  // If a string was passed, make sure it's a fully qualified local date string
  // so we don't accidentally convert it to a UTC date.
  if (typeof date === 'string' && !date.includes('T') && date.includes('-')) {
    date = convertToLocalTime(date)
    // We used to do the following until we started using Hermes which doesn't
    // work with local date strings (ie doesn't like 2022/08/23)
    // date = date.replace(/-/g, '/')
  }

  // Ensure we are working with a Date object.
  return new Date(date)
}

/*
 * Converts the date to local time by getting the miliseconds since epoc time
 * and then adding the offset from UTC in milliseconds and then converting back
 * to a date object. This is also possible via replacing '-' characters with '/'
 * in the date string (ie 2022/02/03) but Hermes js engine doesn't like it.
 */
export const convertToLocalTime = (date: DateLike = new Date()) => {
  const time = new Date(date).getTime()
  const offset = new Date(date).getTimezoneOffset() * 60 * 1000
  const newDate = new Date(time + offset)
  return newDate
}

/**
 * Give a date like '2022-01-01', get a fully qualified ISO local timestamp
 * (ex. '2022-01-01T00:00:00.000'). Any other value will be returned without
 * modification.
 */
export const shortDateToLocalDate = (date: string) => {
  if (!date.includes('T') && date.includes('-')) {
    date += 'T00:00:00.000'
  }
  return date
}

/**
 * Determine if the given date is today.
 */
export function isToday(date: DateLike, bypass = false) {
  const d = normalizeToDateObject(date)
  const now = new Date()
  return bypass
    ? true
    : d.getFullYear() === now.getFullYear() &&
        d.getMonth() === now.getMonth() &&
        d.getDate() === now.getDate()
}

/**
 * Deterimine if it's the first of the month.
 */
export function isFirstOfMonth(date: DateLike) {
  const d = normalizeToDateObject(date)
  return d.getDate() === 1
}

/**
 * Determine the years since a given date and now.
 */
export function yearsSince(date: DateLike, now: DateLike = new Date()) {
  const d = normalizeToDateObject(date)
  const dnow = normalizeToDateObject(now)
  return dnow.getFullYear() - d.getFullYear()
}

/**
 * Add or subtract months from the given date (pass a negative months
 * value to perform subtraction). This method ensures you end up
 * on the previous month but cannot guarantee that the date will stay
 * the same. For example, if date is the 31st of a month who's previous
 * month only has 30 days, the returned date will be the 30th of the
 * previous month (because there is no 31st of that month).
 */
export const addMonths = (
  /**
   * The date relative to which you want to add months.
   */
  date: DateLike = new Date(),
  /**
   * The number of months to add or subtract.
   */
  months = 1,
) => {
  date = normalizeToDateObject(date)
  if (months === 0) return date

  const month = date.getMonth()
  const year = date.getFullYear()
  date.setMonth(date.getMonth() + months)

  // When subtracting a month from the given date, Date will not actually put
  // you in the previous month. It actually subtracts the month and then adds
  // back the current date days. So if the current month has 31 days and the
  // previous month has 30 days and the date is currently the 31st, you'll end
  // up on the 1st of the current month. :'( To resolve this, we'll decrement the
  // day of the month until we reach the last day of the previous month.
  while (date.getMonth() === month && date.getFullYear() === year) {
    date.setDate(date.getDate() - 1)
  }
  return date
}

export const addWeeks = (date: DateLike = new Date(), weeks = 1): Date => {
  const d = normalizeToDateObject(date)
  if (weeks === 0) return d

  const out = new Date(d)
  out.setDate(d.getDate() + weeks * 7)
  return out
}

export const addQuarters = (date: DateLike = new Date(), quarters = 1) => {
  if (quarters === 0) return normalizeToDateObject(date)
  return addMonths(date, quarters * 3)
}

export const addYears = (date: DateLike = new Date(), years = 1) => {
  const d = normalizeToDateObject(date)
  if (years === 0) return d

  const out = new Date(d)
  out.setFullYear(d.getFullYear() + years)
  return out
}

export const addDays = (
  /**
   * The date relative to which you want to add months.
   */
  date: DateLike = new Date(),
  /**
   * The number of days to add or subtract.
   */
  days = 1,
) => {
  date = normalizeToDateObject(date)
  const day = date.getDate()
  const year = date.getFullYear()
  date.setDate(date.getDate() + days)

  // When subtracting a month from the given date, Date will not actually put
  // you in the previous month. It actually subtracts the month and then adds
  // back the current date days. So if the current month has 31 days and the
  // previous month has 30 days and the date is currently the 31st, you'll end
  // up on the 1st of the current month. :'( To resolve this, we'll decrement the
  // day of the month until we reach the last day of the previous month.
  while (date.getDate() === day && date.getFullYear() === year) {
    date.setDate(date.getDate() - 1)
  }
  return date
}

export const nowInMinutes = () => {
  const date = new Date().getTime()
  return new Date(date - (date % 60000))
}

/**
 * Get a date string representing the first day of the current month or the
 * month of the date object/string provided.
 */
export const getStartOfMonth = (date: DateLike = new Date()) => {
  date = normalizeToDateObject(date)
  return formatDateYYYYMMDD(
    new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0),
  )
}

/**
 * Get a date string representing the last day of the current month or the
 * month of the date object/string provided.
 */
export const getEndOfMonth = (date: DateLike = new Date()) => {
  date = normalizeToDateObject(date)
  // A safe place to start from
  date.setDate(28)
  const month = date.getMonth()
  const nextDate = new Date(date)

  // Advance until we get to the next month
  while (nextDate.getMonth() === month) {
    nextDate.setDate(nextDate.getDate() + 1)
    if (nextDate.getMonth() === month) {
      date.setDate(nextDate.getDate())
    }
  }

  return formatDateYYYYMMDD(date)
}

export const isCurrentMonth = (
  date: DateLike,
  currentDate: DateLike = new Date(),
) => {
  date = normalizeToDateObject(date)
  currentDate = normalizeToDateObject(currentDate)
  const newDate = new Date(date.getFullYear(), date.getMonth())
  const newCurrentDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
  )
  return newDate.getTime() === newCurrentDate.getTime()
}

export const isPreviousMonth = (
  date?: DateLike,
  currentDate: DateLike = new Date(),
) => {
  date = normalizeToDateObject(date)
  currentDate = normalizeToDateObject(currentDate)
  const newDate = new Date(date.getFullYear(), date.getMonth())
  const newCurrentDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
  )
  return newDate.getTime() === newCurrentDate.getTime()
}

export const isAPreviousMonth = (
  date?: DateLike,
  currentDate: DateLike = new Date(),
) => {
  date = normalizeToDateObject(date)
  currentDate = normalizeToDateObject(currentDate)
  const newDate = new Date(date.getFullYear(), date.getMonth())
  const newCurrentDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
  )
  return newDate.getTime() <= newCurrentDate.getTime()
}

/**
 * Finalization date is when the data is for a given month is finalized.
 * This data doesn't get finalized until a specific date during the NEXT month
 * For instance, the data for March doesn't get finalized until April 20th.
 * Our startDate is always going to be the 1st so we can't simply see if "now" is greater than the finalization day.
 */
export const isFinalized = (
  startDate: DateLike,
  finalizationDay = 20,
  currentDate: DateLike = new Date(),
) => {
  startDate = normalizeToDateObject(startDate)
  currentDate = normalizeToDateObject(currentDate)

  // Last month
  const isPreviousMonthAndPastFinalizationDate =
    isPreviousMonth(startDate, currentDate) &&
    currentDate.getDate() >= finalizationDay

  // 2 months back and on are awlays finalized
  const isBeforeLastMonth = isAPreviousMonth(
    startDate,
    addMonths(currentDate, -1),
  )

  return isPreviousMonthAndPastFinalizationDate || isBeforeLastMonth
}

/**
 * Determine the date to use as start date if one isn't
 * provided in the URL. This is important because we don't have data for most
 * reports at the beginning of the month. In order to work around that issue we
 * show the previous month until a certain number of days into the month.
 */
export function getDefaultStartDate(
  /**
   * The number of days into the month that we wait before defaulting to the
   * current month as the start date. This should be taken from the ownership
   * group's start date config.
   */
  defaultDay = 25,
  /**
   * The date against which we will compare the default start date in order to
   * get the start date to use.
   */
  d = new Date(),
) {
  // If before Date X, use the previous month
  if (d.getDate() <= defaultDay) d = addMonths(d, -1)

  return formatDateYYYYMMDD(
    new Date(d.getFullYear(), d.getMonth(), 1, 0, 0, 0, 0),
  )
}

/**
 * Helper to give us a start date for different granularities. For instance when
 * you switch to quarterly view on MetricDetails and you are on an off month it
 * will find the beginning month for that quarter.
 */
export function getStartDateBasedOnGranularity(
  initialStartDate: string,
  granularity: string,
) {
  let newStartDate = normalizeToDateObject(initialStartDate)

  // We can change the start date for different granularies
  switch (granularity) {
    case 'month':
      newStartDate = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth(),
        1,
      )
      break
    case 'quarter':
      if (newStartDate.getMonth() <= 2) {
        //Q1
        newStartDate = new Date(newStartDate.getFullYear(), 0, 1) //Jan 1st
      } else if (newStartDate.getMonth() <= 5) {
        //Q2
        newStartDate = new Date(newStartDate.getFullYear(), 3, 1) //April 1st
      } else if (newStartDate.getMonth() <= 8) {
        //Q3
        newStartDate = new Date(newStartDate.getFullYear(), 6, 1) //July 1st
      } else {
        //Q4
        newStartDate = new Date(newStartDate.getFullYear(), 9, 1) //Oct 1st
      }
      break
    case 'year':
      newStartDate = new Date(newStartDate.getFullYear(), 0, 1)
      break
  }

  return formatDateYYYYMMDD(newStartDate)
}

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24

/**
 * Count the number of full days between two date ranges. A day is a period of
 * 24 hours regardless of the start time of the range. For example,
 * `2020-01-01T12:00:00 - 2020-0102T12:00:00` is considered one day but
 * `2020-01-01T12:00:00 - 2020-0102T11:00:00` is consisdered 0 days.
 */
export function getNumberOfDaysBetweenDates(
  startDate: DateLike,
  endDate: DateLike,
) {
  const dateFrom = normalizeToDateObject(endDate)
  const dateTo = normalizeToDateObject(startDate)
  return Math.floor(
    (dateFrom.getTime() - dateTo.getTime()) / MILLISECONDS_IN_DAY,
  )
}

/**
 * Count the number of full weeks between two dates (ie. only full 7 day periods
 * are considered). A week is considered a period of 7 full days regardless of
 * which day of the week is the start of the range.
 */
export function getNumberOfWeeksBetweenDates(
  startDate: DateLike,
  endDate: DateLike,
) {
  return Math.floor(getNumberOfDaysBetweenDates(startDate, endDate) / 7)
}

/**
 * Count the number of complete months between two dates (ie. only full month boundaries are
 * considered). A month is considered based on the month number and the day
 * number. So `01-15 -> 02-15` is considered a full month but `01-15 -> 02-10`
 * is not.
 */
export function getNumberOfMonthsBetweenDates(
  startDate: DateLike,
  endDate: DateLike,
) {
  const dateFrom = normalizeToDateObject(startDate)
  const dateTo = normalizeToDateObject(endDate)

  const fullMonths =
    dateFrom.getFullYear() < dateTo.getFullYear()
      ? // If the dates wrap around years, we need to calculate the distance in
        // months with wrapping. Here we only calculate the distance assuming a
        // a distance of less than 1 year.
        12 - dateFrom.getMonth() + dateTo.getMonth()
      : // If the dates don't wrap around years, we can just subtract them.
        dateTo.getMonth() - dateFrom.getMonth()

  const hasFullMonthInDays = dateTo.getDate() >= dateFrom.getDate()

  // The previous calculation does not account for when the distance in days
  // between the two dates is less than a month. If it was, subtract out one
  // month.
  const fullYearMonths = !hasFullMonthInDays ? fullMonths - 1 : fullMonths

  // The previous calculations handle the case that there is a wrap around of 1
  // year. If there is more than one year difference, we need to add those years
  // as months.
  const years = getNumberOfYearsBetweenDates(startDate, endDate) - 1
  const yearsToAdd = years > 0 ? years * 12 : 0

  return fullYearMonths + yearsToAdd
}

/**
 * Count the number of complete months between two dates, regardless of the day
 * and if there are partial months.
 */
export function getNumberOfNonFullMonthsBetweenDates(
  startDate: DateLike,
  endDate: DateLike,
) {
  const dateFrom = normalizeToDateObject(startDate)
  const dateTo = normalizeToDateObject(endDate)
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear()) +
    // We add +1 because endDate is usually the last day of the month so we want
    // to include that month.
    1
  )
}

/**
 * Count the number of full quarters between two dates. A quarter is considered
 * as a period of 3 months using the same logic as
 * `getNumberOfMonthsBetweenDates`.
 */
export function getNumberOfQuartersBetweenDates(
  startDate: DateLike,
  endDate: DateLike,
) {
  return Math.floor(getNumberOfMonthsBetweenDates(startDate, endDate) / 3)
}

/**
 * Count the number of full years between two dates. A year is considered a
 * period of 12 full months using the `getNumberOfMonthsBetweenDates` logic.
 */
export function getNumberOfYearsBetweenDates(
  startDate: DateLike,
  endDate: DateLike,
) {
  const dateFrom = normalizeToDateObject(startDate)
  const dateTo = normalizeToDateObject(endDate)

  // We need to consider the date of the month and the month of the year as well
  // so that `2020-01-15 -> 2021-01-10` is not considered a full year.
  const fullYears = dateTo.getFullYear() - 1 - dateFrom.getFullYear()
  const fullMonthDays = dateTo.getDate() >= dateFrom.getDate()
  const fullYearMonths =
    dateTo.getMonth() >= dateFrom.getMonth() && fullMonthDays
  return Math.max(fullYearMonths ? fullYears + 1 : fullYears, 0)
}

/*
 * Creates a array of date ranges from startDate to endDate.
 * Each element in the array has a startDate and endDate for each month.
 */
export function createListOfDateRanges(
  startDate: DateLike,
  endDate: DateLike,
): DateRange[] {
  const dateRanges = []
  const numOfMonths = getNumberOfNonFullMonthsBetweenDates(startDate, endDate)

  for (let i = 0; i < numOfMonths; i++) {
    const thisStartDate =
      i === 0
        ? getStartOfMonth(startDate)
        : getStartOfMonth(addMonths(startDate, i))
    const thisEndDate =
      i === 0
        ? getEndOfMonth(startDate)
        : getEndOfMonth(addMonths(startDate, i))

    dateRanges.push({
      startDate: thisStartDate,
      endDate: thisEndDate,
    })
  }

  return dateRanges
}

/**
 * Convert a date to a string with YYYY-MM-DD format. This is useful for
 * filtering API requests by date.
 *
 * NOTE: You should prefer `formatDateToMonth()` for presenting dates to users in locale format.
 */
export function formatDateYYYYMMDD(date: DateLike = new Date()) {
  if (typeof date === 'string' && !date.includes('T')) {
    // If we received an ISO date already in 'YYYY-MM-DD' format, convert it to
    // a fully qualified local ISO date so we don't accidentally convert from
    // locale time to UTC.
    date = new Date(date + 'T00:00:00.000')
  } else {
    // Shouldn't need this I don't think. Breaking on Team page without it.
    date = new Date(date)
  }

  // Date.toISOString().split('T')[0] is similar but the timezone shift can end up being a different day
  // So this just does a straigth conversion to a string.
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`
}

/**
 * Get the year for a date.
 */
export function formatYear(date: DateLike = new Date()) {
  date = normalizeToDateObject(date)
  return date.getFullYear()
}

/**
 * Get the quarter with or without the year
 */
export const formatQuarter = (date?: DateLike, includeYear = false) => {
  date = normalizeToDateObject(date)
  const month = date.getMonth()
  let quarter: string
  if (month <= 2) {
    quarter = 'Q1'
  } else if (month <= 5) {
    quarter = 'Q2'
  } else if (month <= 8) {
    quarter = 'Q3'
  } else {
    quarter = 'Q4'
  }

  return includeYear ? `${quarter} ${date.getFullYear()}` : quarter
}

/**
 * Get the month name for a date. Since this uses `Intl.DateTimeFormat`
 * under the hood, you may pass any options of that constructor.
 */
export function formatMonth(
  date: DateLike = new Date(),
  /**
   * Options to pass to `Intl.DateTimeFormat`
   */
  options?: Bag,
  locale = getUserLocale(),
) {
  date = normalizeToDateObject(date)

  if (isNaN(date.getTime())) return ''

  if (global.Intl) {
    try {
      const f = new Intl.DateTimeFormat(locale, {
        month: 'long',
        ...options,
      })

      // Ensure strings are converted to dates first.
      return f.format(date)
    } catch (e) {
      console.warn(
        '[utils/formatMonth] Failed: Intl.NumberFormat:',
        locale,
        date,
        options,
      )
      console.warn('[utils/formatMonth] original error:', e)
    }
  }

  return date.toString().substr(4, 3)
}

/**
 * Get the abbreviated name for a month.
 */
export function formatMonthShort(
  date: DateLike = new Date(),
  /**
   * Options to pass to `Intl.DateTimeFormat`
   */
  options?: Bag,
  locale = getUserLocale(),
) {
  date = normalizeToDateObject(date)
  return formatMonth(date, { month: 'short', ...options }, locale)
}

/**
 * Format a date/month/year combo for display to the user in their locale.
 * Example: 'May 10, 2022'
 */
export const formatDate = (
  date: DateLike = new Date(),
  locale?: string | string[],
  options?: Intl.DateTimeFormatOptions,
) => {
  date = normalizeToDateObject(date)
  return formatDateToMonth(date, locale, { day: 'numeric', ...options })
}

/**
 * Format a month/year combo for display to the user using their locale settings.
 * Example: 'May 2022'
 */
export const formatDateToMonth = (
  date: DateLike = new Date(),
  /**
   * The desired locale. Leaving this blank will fallback to the user's
   * configured locale.
   */
  locale: string | string[] = getUserLocale(),
  /**
   * Any options you'd like to pass to `Intl.DateTimeFormat`.
   */
  options: Intl.DateTimeFormatOptions = {},
) => {
  date = normalizeToDateObject(date)
  if (global.Intl) {
    try {
      return Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        ...options,
      }).format(date)
    } catch (e) {
      console.warn(
        '[utils/date] Failed: Intl.DateTimeFormat:',
        locale,
        options,
        date,
      )
      console.warn('[utils/date] original error:', e)
      // Fallback to the default implementation below...
    }
  }

  // As a fallback, just return something useful. This should only
  // occur in Expo Go on Android as we provide the Intl object as a plugin during EAS
  // builds.
  return formatDateYYYYMMDD(date).split('-').slice(0, 2).join('-')
}

const MIN = 60000
const HOUR = 3600000

/**
 * Determine the total number of hours represented by the given milliseconds.
 * The value is truncated so any minutes/seconds above the hour are ignored.
 */
function milliToTotalHours(milli: number) {
  return Math.floor(milli / HOUR)
}

/**
 * Format a duration of time to display to the user with their locale settings (ex 14:22:17)
 */
export function formatTimeDuration(
  /**
   * The time in milliseconds
   */
  milliseconds: number,
  /**
   * Options to pass to `Intl.DateTimeFormat`
   */
  options?: Bag,
  locale = getUserLocale(),
) {
  const time = new Date(milliseconds)

  // If the date is invalid, return 0 time
  if (isNaN(time.getTime())) return '00:00:00'

  // Some locales format times using a dot '00.00.00'
  // so use Intl if available.
  if (global.Intl) {
    try {
      const f = new Intl.DateTimeFormat(locale, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
        ...options,
      })

      return f
        .formatToParts(time)
        .map(({ type, value }) => {
          switch (type) {
            case 'hour':
              return String(milliToTotalHours(milliseconds)).padStart(2, '0')
            default:
              return value
          }
        })
        .join('')
    } catch (e) {
      console.warn(
        '[utils/formatTime] Failed: Intl.DateTimeFormat:',
        locale,
        time,
        options,
      )
      console.warn('[utils/formatTime] original error:', e)
    }
  }

  return [
    String(milliToTotalHours(milliseconds)).padStart(2, '0'),
    String(Math.floor((milliseconds / MIN) % 60)).padStart(2, '0'),
    String(Math.round((milliseconds / 1000) % 60)).padStart(2, '0'),
  ].join(':')
}

/**
 * Get an array of the months between two dates. The dates in the returned array
 * will be the first day of the month.
 */
export function getMonthsInRange(minDate: DateLike, maxDate: DateLike) {
  maxDate = normalizeToDateObject(getEndOfMonth(maxDate))

  const months: Date[] = []
  let currDate = normalizeToDateObject(getStartOfMonth(minDate))

  while (currDate <= maxDate) {
    months.push(currDate)
    currDate = addMonths(currDate, 1)
  }

  return months
}
