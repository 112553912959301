import { Platform } from 'react-native'
import { makeStyledText } from './colors'
import { state } from './state'
import { LogLevel } from './types'

// Functions we want to enable when the debug flag is on.
export const debugFunctions = [
  'debug',
  'log',
  'info',
  'trace',
  'dir',
  'table',
  'group',
  'groupCollapsed',
]
// Functions we want to always enable.
export const errorFunctions = ['warn', 'error']
export const allFunctions = [...debugFunctions, ...errorFunctions]

/**
 * Calls the provided callback only if the log event should not be filtered out.
 */
export const filterLogEvent = (
  doLog: () => void,
  level: LogLevel,
  prefix: string,
  argArray: any[],
) => {
  if (level >= state.level) {
    if (state.blacklist.length > 0 || state.whitelist.length > 0) {
      const useWhitelist = state.whitelist.length > 0
      const list = useWhitelist ? state.whitelist : state.blacklist

      const logEntry = [prefix, ...argArray]
        .join(' ')
        // Remove any color CSS from the log entry
        .replace(/%c/g, '')
      const matches = list.some((filter) => logEntry.match(filter))
      if ((useWhitelist && matches) || (!useWhitelist && !matches)) {
        // If none of the filters were matched, call through
        return doLog()
      } else {
        return
      }
    } else {
      // If this log function is less that then current log level,
      // call through
      return doLog()
    }
  }
}

/**
 * Get a property on our console proxy (ex. get the 'debug' property on
 * console). The returned property will either be the original property, a
 * function bound on console which logs with the prefix and correct line numbers
 * or a function that handles log filtering.
 */
export const getConsoleProperty = (
  /**
   * An object with bound log functions that will log with the correct prefix
   * and line numbers.
   */
  bindings: object,
  /**
   * The original target object (ie. console).
   */
  target: object,
  /**
   * The console property we want to call.
   */
  prop: string | symbol,
  /**
   * The `this` value when we call the console property.
   */
  receiver: object,
  /**
   * Whether the current logger instance is currently enabled.
   */
  instanceEnabled: boolean,
  /**
   * A callback that should return a filtering log function. The returned
   * function will be the console property used at run time if log
   * filtering needs to happen.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  makeFunctionProxy: (prop: string | symbol, l: LogLevel) => Function,
  /**
   * A callback that will call through to the original console property. On web,
   * this function will just call `Reflect.get` but on other environments it
   * should return `target[prop]`.
   */
  callThrough: (target: any, prop: string | symbol, receiver: any) => any,
) => {
  // If logging is globally disabled and we haven't explicitly enabled this
  // logger, the prevent logging.
  if (state.disabled && !instanceEnabled) {
    // no-op
    return () => {}
  } else if (typeof prop === 'string' && allFunctions.includes(prop)) {
    // If there are currently any filters, then return a function that will
    // handle filtering. Unfotunately, this will report incorrect line
    // numbers in the console but we should only be using the filters during
    // testing where console line numbers are not shown.
    if (
      // We're using the blacklist
      state.blacklist.length > 0 ||
      // We're using the whitelist
      state.whitelist.length > 0 ||
      // We're using the log level filtering
      state.level > LogLevel.DEBUG ||
      // We're using a log storage backend
      !!state.storage
    ) {
      const level = errorFunctions.includes(prop)
        ? LogLevel.ERROR
        : LogLevel.DEBUG
      return makeFunctionProxy(prop, level)
    } else {
      // Return the bound function that will log with the correct line
      // numbers and prefix.
      return bindings[prop] || callThrough(target, prop, receiver)
    }
  }

  // Call through to the original console property without any prefixing.
  return callThrough(target, prop, receiver)
}

/**
 * Add console logging methods that will prefix log statements to the given
 * object.
 */
export function makePrefixedLogger(
  /**
   * The item to receive logging functionality.
   */
  item: object,
  /**
   * A prefix to add before all logs genterated by the new logger.
   */
  _prefix?: string,
  /**
   * Styling to apply to the prefix text to help you find it in the console..
   */
  styles?: Record<string, string>,
) {
  const prefix = _prefix ? `[${_prefix}]` : undefined
  const pre = makeStyledText(prefix, styles)

  // By calling `console.x.bind`, we ensure that the correct line numbers are
  // logged in the browser developer tools.
  const bind = (level) => {
    if (typeof console[level] === 'function') {
      return !prefix
        ? console[level].bind(console)
        : console[level].bind(console, ...pre)
    } else {
      // Some console properties are not available in production builds and
      // cause crashes if called (table, group, etc). Provide an no-op for those
      // cases.
      // istanbul ignore next
      return () => {}
    }
  }

  // Add logging functions to the item.
  allFunctions.forEach((level) => {
    item[level] = bind(level)
  })

  if (Platform.OS !== 'web') {
    // `console.groupCollapsed` logs are hidden in the terminal so use
    // `console.group` instead.
    const i = item as Console
    i.groupCollapsed = i.group
  }

  return prefix
}

/**
 * Convert an object with CSS styles into a CSS string.
 *
 * @example
 * stylesToCSS({ color: 'red', 'font-weight': 'bold' }) // => "color:red;font-weight:bold;"
 */
export function stylesToCSS(styles?: Record<string, string>): string {
  return (
    styles &&
    Object.keys(styles).reduce((acc, key) => `${acc}${key}:${styles[key]};`, '')
  )
}

/**
 * Convert a CSS string into an object.
 */
export function cssToStyles(css?: string): Record<string, string> {
  if (!css) return {}
  return css.split(';').reduce((acc, def) => {
    const [key, value] = def.split(':')
    if (!key || !value) return acc
    else return { ...acc, [key.trim()]: value.trim() }
  }, {})
}

function argIncludesStyles(arg: any) {
  return typeof arg === 'string' && arg.includes('%c')
}

/**
 * Handle colors inside the message being logged.
 *
 * The console API allows colorizing the output color by passing a string with
 * `%c` markers. If any `%c` markers are found in the first argument, then the
 * following args are treated as CSS style declarations.
 */
export function handleColoredOutput(argArray: any[]): any[] {
  if (
    Platform.OS !== 'web' &&
    argArray?.length &&
    argIncludesStyles(argArray[0])
  ) {
    const styleFirstMessage = argArray[0].startsWith('%c')

    return argArray[0]
      .split('%c')
      .filter((v: any) => !!v)
      .flatMap((message: any, index: number) => {
        const m = message.trim()
        const cssDefIndex = styleFirstMessage ? 1 + index : index
        // If we didn't receive enough CSS definitions, don't do any further styling
        const nextCSSDef =
          cssDefIndex >= argArray.length ? undefined : argArray[cssDefIndex]

        // If we don't have a CSS definition or if this is the first string and
        // it isn't supposed to be styled, return the plain message.
        if (!nextCSSDef || (index === 0 && !styleFirstMessage)) return m
        else return makeStyledText(m, cssToStyles(nextCSSDef))
      })
  } else {
    return argArray
  }
}
